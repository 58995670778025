import React, { useState } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

const AgendaBlock = ({ block }) => {
  const [activeTabState, setActiveTabState] = useState(1)
  return (
    <BlockWrapper block={block} showHeadline="true" centerHeadline="true">
      <nav className="flex flex-row flex-wrap items-stretch mt-8 justify-left lg:justify-center md:flex-nowrap">
        {block.tabs?.length > 0 &&
          block.tabs.map((tab, index) => (
            <button
              className={` w-1/2 md:w-auto px-4 lg:px-8 py-2 border border-primary focus:outline-none ${
                index === 0 ? "lg:rounded-l-full" : ""
              } ${
                index === block.tabs.length - 1 ? " lg:rounded-r-full" : ""
              } ${
                activeTabState === index + 1
                  ? "bg-primary text-white"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTabState(index + 1)}
              style={{ marginTop: "-1px", marginRight: "-1px" }}
            >
              <span
                className="block font-bold leading-tight lg:text-md"
                style={{ hyphens: "auto" }}
              >
                {tab.title}
              </span>
              <span className="block mt-2 text-sm leading-right">
                {tab.subtitle}
              </span>
            </button>
          ))}
      </nav>
      {block.tabs?.length > 0 &&
        block.tabs.map((tab, index) => (
          <div
            className={`relative py-4 mt-8 ${
              activeTabState === index + 1 ? "block" : "hidden"
            }`}
          >
            {tab.events?.length > 0 && (
              <>
                <div className="absolute top-0 bottom-0 w-1/3 border-r-2 border-gray-400 md:w-1/4"></div>
                <div className="flex flex-row flex-wrap">
                  {tab.events.map((item, index) => (
                    <>
                      <div className="relative block w-1/3 py-4 md:w-1/4">
                        {item.time && (
                          <>
                            <span className="block w-full pr-4 font-bold leading-7 text-right lg:pr-12 sm:pr-8 text-primary">
                              {item.time}
                            </span>
                            <span
                              className="absolute top-0 right-0 block w-4 h-4 rounded-full bg-primary"
                              style={{
                                transform: "translate(7px, 1.375rem)",
                              }}
                            ></span>
                          </>
                        )}
                      </div>
                      <div className="w-2/3 py-4 pl-4 md:w-3/4 sm:pl-8 lg:pl-16">
                        <Richtext text={item.text} />
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
    </BlockWrapper>
  )
}

export default AgendaBlock
