import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="content"
      showHeadline="true"
      showButtons="true"
      blockDirection="vertical"
      className=""
    >
      <TextReveal>
        <Richtext
          flow={block.flow}
          text={block.text}
          className={` ${block.text_size === "text-lg" ? block.text_size : ""}`}
        />
      </TextReveal>
    </BlockWrapper>
  )
}

export default TextBlock
