import React, { useRef, useEffect } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

gsap.registerPlugin(ScrollTrigger)

const useCountUp = () => {
  const refCountUpTrigger = useRef(null)
  const refCountUp = useRef([])
  refCountUp.current = []

  const refCountUpCollection = (el) => {
    if (el && !refCountUp.current.includes(el)) {
      refCountUp.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refCountUp.current, {
      duration: 1,
      stagger: 0.25,
      ease: "ease.out",
      innerText: 0,
      snap: { innerText: 1 },
      scrollTrigger: {
        trigger: refCountUpTrigger.current,
        toggleActions: "play none none reverse",
        start: "start 75%",
        end: "bottom 25%",
      },
    })
  }, [])
  return [refCountUpTrigger, refCountUpCollection]
}

const StatsBlock = ({ block }) => {
  const [refCountUpTrigger, refCountUpCollection] = useCountUp()
  return (
    <BlockWrapper
      block={block}
      showHeadline="true"
      centerHeadline="true"
      className="bg-gray-100"
    >
      <div
        ref={refCountUpTrigger}
        className="flex flex-row flex-wrap justify-center -mx-grid"
      >
        {block.stat_items?.length > 0 &&
          block.stat_items.map((item, index) => (
            <div className="w-full mt-8 md:w-1/2 lg:w-1/4 px-grid" key={index}>
              <div className="text-center">
                <span
                  ref={refCountUpCollection}
                  className="inline-block mb-4 text-4xl font-bold text-primary"
                >
                  {item.number}
                </span>
                {item.unit && (
                  <span className="ml-4 text-4xl font-semibold text-primary">
                    {item.unit}
                  </span>
                )}
                <span className="block mb-4 text-lg">{item.title}</span>
                {item.text && <Richtext text={item.text} />}
                <Button link={item.link}>{item.link_text}</Button>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default StatsBlock
