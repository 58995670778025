import React from "react"

import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

const Card = ({ item }) => {
  return (
    <article className="h-full hover-shadow group">
      <Link
        className="block overflow-hidden group"
        link={`/${item.link.cached_url}`}
      >
        {item.image && item.image.filename && (
          <Image
            className="object-cover w-full duration-200 transform"
            image={item.image}
            aspectRatio="16by9"
          />
        )}
        <div className="p-4">
          <div className="lg:text-lg">
            <Headline
              kicker={item.kicker}
              headline={item.headline}
              headline_level="h4"
            />
            {item.text?.length > 0 && <p className="mt-4 prose">{item.text}</p>}
            {item.link_text?.length > 0 && (
              <span className="inline-block mt-4 text-primary group-hover:text-primary-hover">
                {item.link_text}
              </span>
            )}
          </div>
        </div>
      </Link>
    </article>
  )
}

export default Card
