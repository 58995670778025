import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt } from "@fortawesome/pro-solid-svg-icons"
import {
  faFacebookF,
  faLinkedin,
  faTwitter,
  faXing,
} from "@fortawesome/free-brands-svg-icons"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const ShareButton = ({ btnType, className }) => {
  const typeClasses = {
    black: "text-black hover:underline",
    white: "text-white hover:underline",
  }
  const [shareState, setShareState] = useState("close")
  const shareClickHandler = (index) => {
    if (shareState === "close") {
      setShareState("open")
    } else {
      setShareState("close")
    }
  }
  let currentUrl = ""
  if (typeof window !== "undefined") {
    currentUrl = window.location.href
  }
  return (
    <div className={`absolute top-0 right-0 ${className ? className : ""}`}>
      <button
        className={`px-4 py-0 text-right block w-full focus:outline-none ${typeClasses[btnType]}`}
        onClick={() => shareClickHandler("open")}
      >
        <span className="inline-block w-4 h-5 mr-2">
          <FontAwesomeIcon icon={faShareAlt} size="1x" className="" />
        </span>
        Teilen
      </button>
      <div
        className={`flex flex-col w-full overflow-hidden h-0 ${
          shareState === "open" ? "h-full" : ""
        }`}
      >
        <Link
          link={`https://twitter.com/intent/tweet?url=${currentUrl}`}
          className={`px-4 text-right ${typeClasses[btnType]}`}
        >
          <span className="inline-block w-4 h-5 mr-2">
            <FontAwesomeIcon icon={faTwitter} size="1x" className="" />
          </span>
          Twitter
        </Link>
        <Link
          link={`https://www.facebook.com/sharer.php?u=${currentUrl}`}
          className={`px-4 text-right ${typeClasses[btnType]}`}
        >
          <span className="inline-block w-4 h-5 mr-2">
            <FontAwesomeIcon icon={faFacebookF} size="1x" className="" />
          </span>
          Facebook
        </Link>
        <Link
          link={`https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`}
          className={`px-4 text-right ${typeClasses[btnType]}`}
        >
          <span className="inline-block w-4 h-5 mr-2">
            <FontAwesomeIcon icon={faLinkedin} size="1x" className="" />
          </span>
          LinkedIn
        </Link>
        <Link
          className={`px-4 text-right ${typeClasses[btnType]}`}
          link={`https://www.xing.com/spi/shares/new?url=${currentUrl}`}
        >
          <span className="inline-block w-4 h-5 mr-2">
            <FontAwesomeIcon icon={faXing} size="1x" className="" />
          </span>
          Xing
        </Link>
      </div>
    </div>
  )
}

export default ShareButton
