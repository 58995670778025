import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import useFetch from "@yumgmbh/gatsby-theme-yum-components/src/hooks/useFetch"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const EventListBlock = ({ block }) => {
  const { data: products, loading } = useFetch(
    `${process.env.SHOP_API}?q[product_group]=21&q[product_subgroups][]=${block.format}&q[product_topics][]=${block.topic}&per=${block.limit}`
  )

  return (
    <BlockWrapper block={block} blockWidth="content" className="">
      {loading && <></>}
      {products &&
        products?.map((product, index) => (
          <Link
            key={index}
            link={`https://shop.bme.de${product.product_web_path}`}
            className="block mb-2 text-lg text-primary hover:text-primary-hover"
          >
            {product.title}
          </Link>
        ))}
      <div>
        <Link
          className="block mb-2 text-lg text-primary hover:text-primary-hover font-bold"
          link={`https://shop.bme.de/products?q[product_group]=21&q[product_subgroups]=${block.format}&q[product_topics][]=${block.topic}`}
        >
          Alle Anzeigen
        </Link>
      </div>
    </BlockWrapper>
  )
}

export default EventListBlock
