import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const LogoGridBlock = ({ block }) => {
  const sizes = {
    small: "p-4",
    normal: "p-2",
    large: "p-0",
  }
  return (
    <BlockWrapper block={block} className="bg-gray-100" showHeadline="true">
      <div className="flex flex-row flex-wrap pt-8 -mx-grid">
        {block.logo_items?.length > 0 &&
          block.logo_items.map((item, index) => (
            <div
              className="relative w-1/2 md:w-1/3 lg:w-1/4 px-grid"
              key={index}
            >
              <div className="flex items-center justify-center w-full h-full mb-8 text-center ">
                <Link
                  className="relative block w-full h-auto bg-white pb-2/3"
                  link={item.link}
                >
                  <div className="absolute inset-0 w-auto h-full p-4">
                    <div className={`h-full w-full ${sizes[item.logo_size]}`}>
                      <Image
                        image={item.image}
                        className={`object-contain object-center h-full w-full `}
                      />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default LogoGridBlock
