import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import HeadlineReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"

const QuoteBlock = ({ block }) => {
  // Detect if it has image
  let hasImage = false
  if (block.image?.filename?.length > 0) {
    hasImage = true
  }
  // Text left or right?
  let rightImage = false
  if (block.quote_position && block.quote_position === "right") {
    rightImage = true
  }

  // Get Styling of quote
  const quoteStyle = {
    "text-primary": "text-primary",
    "bg-lines-primary": "bg-lines-shadow-primary bg-primary text-white",
    "text-light-blue": "text-bme-light-blue",
    "bg-lines-light-blue":
      "bg-lines-shadow-light-blue bg-bme-light-blue text-white",
    "text-gold": "text-bme-gold",
    "bg-lines-gold": "bg-lines-shadow-gold bg-bme-gold text-white",
  }
  let styling = ""
  if (block.quote_style?.length > 0) {
    styling = quoteStyle[block.quote_style]
  } else {
    styling = quoteStyle["text-primary"]
  }
  return (
    <>
      {block.quote && block.quote.length > 0 && (
        <BlockWrapper
          block={block}
          blockWidth={hasImage ? "container" : "content"}
          blockPadding={hasImage ? "py-block" : "py-24"}
        >
          <blockquote className={`relative ${hasImage ? "" : "sm:px-16"}`}>
            {hasImage && (
              <Image
                className="block w-full h-auto"
                image={block.image}
                aspectRatio="16by9"
              />
            )}
            <div
              className={`${
                hasImage ? "md:absolute inset-0" : ""
              } flex flex-col ${
                rightImage ? "items-end" : "items-start"
              } justify-center`}
            >
              <div
                className={`${
                  hasImage ? "mt-4 md:mt-0 md:w-1/2 xl:w-2/5" : "w-full"
                } ${hasImage ? (rightImage ? "md:pr-8" : "md:pl-8") : ""}`}
              >
                <HeadlineReveal>
                  <p
                    className={`relative inline font-bold text-2xl leading-relaxed lg:text-3xl ${styling}`}
                  >
                    <span className="italic">„</span>
                    {block.quote}
                    <span className="italic">”</span>
                  </p>
                </HeadlineReveal>
              </div>
            </div>
            {block.author?.length > 0 && (
              <footer className="mt-4">
                <span className={`${hasImage ? "inline-" : ""}block font-bold`}>
                  {block.author}
                </span>
                {block.author_description?.length > 0 && (
                  <>
                    {hasImage ? " - " : ""}
                    <span className={`${hasImage ? "inline-" : ""}block`}>
                      {block.author_description}
                    </span>
                  </>
                )}
              </footer>
            )}
          </blockquote>

          {/* <div className="flex flex-row flex-wrap -mx-grid">
            {block.image?.filename && (
              <div className="w-full lg:w-1/3 px-grid">
                <ImageReveal>
                  <Image
                    className="w-full h-auto max-w-xs mx-auto"
                    image={block.image}
                  />
                </ImageReveal>
              </div>
            )}
            <div
              className={`w-full ${
                block.image?.filename && "lg:w-2/3 pt-8 lg:pt-0"
              } px-grid`}
            >
              <blockquote className="pl-12">
                <HeadlineReveal>
                  <p className="relative block text-4xl italic font-black leading-relaxed text-primary">
                    <span className="absolute -mt-8 -ml-16 italic text-primary text-8xl">
                      „
                    </span>
                    {block.quote}
                    <span className="text-5xl italic font-bold text-primary"></span>
                  </p>
                </HeadlineReveal>
                {block.author?.length > 0 && (
                  <footer className="mt-8">
                    <TextReveal>
                      <span className="block text-lg font-bold">
                        {block.author}
                      </span>
                    </TextReveal>
                    <TextReveal>
                      <span className="block">{block.author_description}</span>
                    </TextReveal>
                  </footer>
                )}
              </blockquote>
            </div>
          </div> */}
        </BlockWrapper>
      )}
    </>
  )
}

export default QuoteBlock
