import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-solid-svg-icons"

const DownloadsBlock = ({ block }) => {
  const getFileName = (file) => {
    if (file) {
      let fileName = file.split("/")
      fileName = fileName[fileName.length - 1]
      return fileName
    }
  }

  const getFileExtension = (file) => {
    if (file) {
      let fileType = file.split(".")
      fileType = fileType[fileType.length - 1]
      fileType = fileType.toUpperCase()
      return fileType
    }
  }

  return (
    <BlockWrapper
      block={block}
      blockWidth="content"
      blockDirection="horizontal"
      showHeadline="true"
      className="bg-gray-100"
    >
      {block.download_items?.length > 0 &&
        block.download_items.map((item, index) => (
          <Link
            className="block py-4 border-b-2 border-gray-300 text-primary hover:text-primary-hover"
            link={item.file.filename}
          >
            <div className="flex flex-row" key={index}>
              <div className="flex">
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                  className="mt-1.5 mr-4"
                />
              </div>
              <div className="">
                <span className="block text-lg font-bold">
                  {item.title?.length > 0
                    ? item.title
                    : getFileName(item.file.filename)}
                </span>
                <span className="inline-block prose">
                  {getFileExtension(item.file.filename)}
                  {item.description?.length > 0 && (
                    <>
                      <br />
                      {item.description}
                    </>
                  )}
                </span>
              </div>
            </div>
          </Link>
        ))}
    </BlockWrapper>
  )
}

export default DownloadsBlock
