import React, { useEffect, useState } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"

const CountdownBlock = ({ block }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTime())
    }, 1000)
    return () => clearTimeout(timer)
  })

  const calculateTime = () => {
    const difference = new Date(block.date) - new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTime())

  return (
    <BlockWrapper block={block} blockWidth="container">
      <div className="flex flex-row flex-wrap justify-between -mx-grid bg-primary">
        <div className="flex flex-col flex-wrap py-4 px-grid">
          <span className="block text-blue-300 kicker-h3">{block.kicker}</span>
          <span className="block text-white headline-h3">{block.title}</span>
          <span className="block text-blue-300 subheadline-h3">
            {block.subtitle}
          </span>
        </div>
        <div className="px-grid ">
          <div className="flex flex-col flex-wrap text-white lg:flex-row -mx-grid">
            <div className="flex flex-col flex-wrap py-4 px-grid">
              {block.date?.length > 0 && (
                <>
                  <span className="block w-full lg:text-center">
                    {block.countdown_intro}
                  </span>
                  <div className="flex flex-row w-full -mx-2 lg:mx-0 lg:text-center lg:justify-center">
                    <div className="px-2">
                      <span className="inline-block px-4 py-1 mt-2 text-2xl leading-8 bg-black">
                        {timeLeft.days}
                      </span>
                      <span className="block mt-1">Tage</span>
                    </div>
                    <div className="px-2">
                      <span className="inline-block px-4 py-1 mt-2 text-2xl leading-8 bg-black">
                        {timeLeft.hours}
                      </span>
                      <span className="block mt-1">Stunden</span>
                    </div>
                    <div className="px-2">
                      <span className="inline-block px-4 py-1 mt-2 text-2xl leading-8 bg-black">
                        {timeLeft.minutes}
                      </span>
                      <span className="block mt-1">Minuten</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center py-4 px-grid">
              {block.link_text?.length > 0 && (
                <Link
                  className="block p-2 text-white border border-white"
                  link={block.link}
                >
                  {block.link_text}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    size="1x"
                    className="ml-2"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default CountdownBlock
